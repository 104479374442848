import React from 'react';
import './css/StaticNavHeader.css'; // Import the CSS for styling

const StaticNavHeader = () => {
    return (
        <div className="static-nav-header">
            <img src='/assets/social-wordle.png' alt="Social Wordle Header" className="header-image" />
        </div>
    );
};

export default StaticNavHeader;
