import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import FeedPage from './pages/FeedPage';
import BattleGame from './components/BattleGame';
import Wordle from './pages/WordlePage';
import Feed from './pages/FeedPage';
import Profile from './pages/ProfilePage';
import ProfilePageExternalView from './pages/ProfilePageExternalView';
import ScrollToTop from './components/ScrollToTop';
import PostDetail from './components/PostDetail';
import BackAppBar from './components/BackAppBar';
import useViewportHeight from './hooks/useViewportHeight';
import WordleOverlayComponent from './components/WordleOverlayComponent';
import { saveUser } from './reducers/user';
import { backendDomain } from './constants';
import { requestNotificationPermission, subscribeUser } from './utils/notificationUtils';



function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [solution, setSolution] = useState(null);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("");
  const [existingGuesses, setExistingGuesses] = useState([]);
  const [formattedGuesses, setFormattedGuesses] = useState([]);
  const [gameId, setGameId] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loggedIn, setLoggedIn] = useState(null);

 // const [shouldRedirect, setShouldRedirect] = useState(false);

  useViewportHeight();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setLoggedIn(true);
      dispatch(saveUser(storedUser));
    } else {
      setLoggedIn(false);
      dispatch(saveUser(null));
    }
  }, [dispatch]);

  useEffect(() => {
    if (user.status === 'loading') return;
    if (user.status === 'failed') {
      console.error('Error loading user:', user.error);
      return;
    }

    if (user._id) {
      setLoggedIn(true);
      async function fetchWordle() {
        await fetch(`${backendDomain}/wordle/generate-wordle`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user._id }),
        })
          .then(res => res.json())
          .then(json => {
            const { solution, guesses, formattedGuesses, _id, message, result } = json.wordle;
            setSolution(solution);
            setResult(result);
            setExistingGuesses(guesses || []);
            setFormattedGuesses(formattedGuesses || []);
            setGameId(_id);
            setMessage(message);
          })
          .catch(err => console.error('Error fetching Wordle:', err));
      }
      fetchWordle();
    } else {
      console.log('App: User not logged in. No wordle');
    }
  }, [user._id]);

  //console.log("Redirect logic variables: Solution", solution, "loggedIn", loggedIn, "userid", user._id, "result", result);
  // Only redirect on initial load and when at root path
  const shouldRedirect =  location.pathname === '/' && ( loggedIn === false || (loggedIn && result === "in_progress"));

  //console.log("Final redirect", shouldRedirect);

  return (
    <>
      {shouldRedirect && <Navigate to="/wordle" replace />}
      <ScrollToTop />
      <LocationHandler />
      <Routes>
        <Route path="/" element={<Feed />} />
        <Route path="/feed" element={<Feed />} />
        <Route path="/wordle" element={
          <Wordle 
            solution={solution}
            existingGuesses={existingGuesses}
            formattedGuesses={formattedGuesses}
            gameId={gameId}
            user={user}
            message={message}
          />
        } />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/profile/:id" exact element={<ProfilePageExternalView />} />
        <Route path="/post/:postId" element={<PostDetail />} />
        <Route path="/battle/:battleId" element={<BattleGame />} />
      </Routes>
    </>
  );
}

function LocationHandler() {
  const location = useLocation();
  const isPostDetailPage = location.pathname.startsWith('/post/');
  const isBattlePage = location.pathname.startsWith('/battle/');

  return (isPostDetailPage) ? <BackAppBar /> : null;
}

export default App;
