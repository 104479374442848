/**
 * PostOverview component displays a single post with user information, 
 * likes functionality, and the Wordle game grid.
 * 
 * Props:
 * - user: Object containing user information (e.g., name, imageUri).
 * - wordle: Object containing Wordle game data (e.g., message, likers).
 * - isFirstOfDay: Boolean indicating whether the post is the first of the day.
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { backendDomain } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import LikesOverview from "./LikesOverview";
import { saveUser } from "../reducers/user";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import './css/PostOverview.css';
/*import CommentIcon from '@mui/icons-material/MapsUgcRounded';*/
import { FaRegComment } from "react-icons/fa";
import { LuSwords } from "react-icons/lu";
import { format, parseISO, isToday, isYesterday, startOfDay } from 'date-fns'; // Add this import
import { battleService } from '../hooks/battleService';
import getSecurityInfo from '../utils/securityUtils';

const PostOverview = ({ user, wordle, isFirstOfDay, isProfilePage }) => {
    // State variables for managing likes, streak, visibility of likes overview, like count, and delete dialog
    const [liked, setLiked] = useState(false);
    const [showLikesOverview, setShowLikesOverview] = useState(false);
    const [likeCount, setLikeCount] = useState(wordle.likers.length);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [commentCount, setCommentCount] = useState(wordle.comments ? wordle.comments.length : 0);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState(""); // State for new comment input
    const navigate = useNavigate();
    const loggedInUser = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [isBattleStarting, setIsBattleStarting] = useState(false);
    const [showBattleWordlePrompt, setShowBattleWordlePrompt] = useState(false);
    const [showPlayWordlePrompt, setShowPlayWordlePrompt] = useState(false);

    // Effect to check if a user is already logged in and dispatch user data
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            console.log("Already logged in");
            dispatch(saveUser(storedUser));
        }
    }, [dispatch]);

    // Effect to set the liked state based on whether the logged-in user has liked the post
    useEffect(() => {
        if (loggedInUser)
            setLiked(wordle.likers.includes(loggedInUser._id));
    }, []);

    // Function to handle like button click
    const handleLikeClick = async () => {
        if (!loggedInUser._id) return;

        setLiked(!liked);
        const reqBody = { userId: wordle.userId, delta: 1 }
        if (wordle.likers.includes(loggedInUser._id))
            reqBody.delta = -1
        
        //This updates total likes received by a user
        await fetch(`${backendDomain}/user/update-likes`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(reqBody),
        });

        if (wordle.likers.includes(loggedInUser._id))
            wordle.likers = wordle.likers.filter(liker => liker !== loggedInUser._id)
        else
            wordle.likers.push(loggedInUser._id);
        setLikeCount(wordle.likers.length)
        console.log(wordle)
        //Client should not be updating the wordle object. Create a separate API just for the likes on the post
        await fetch(`${backendDomain}/wordle/update-wordles-game-for-existing-user`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id: wordle._id, wordle: wordle }),
        });
    };

    // Function to navigate to the user's profile
    const handleNameClick = () => {
        navigate(`/profile/${user._id}`);
    };

    // Function to show likes overview
    const handleLikesNumberClick = () => {
        setShowLikesOverview(true);
    };

    // Function to close the likes overview
    const closeLikesOverview = () => {
        setShowLikesOverview(false);
    };

    // Function to handle canceling the delete dialog
    const handleDeleteCancel = () => {
        setShowDeleteDialog(false);
    };

    // Function to toggle the delete confirmation dialog
    const handleDeleteClick = () => {
        setShowDeleteDialog(!showDeleteDialog);
    };

    // Function to confirm deletion of the post
    const handleDeleteConfirm = async () => {
        try {
            setShowDeleteDialog(false);
            
            // Gather security information
            const securityInfo = await getSecurityInfo();
            
            // Create comprehensive request payload
            const payload = {
                wordleId: wordle._id,
                userId: loggedInUser._id,
                userEmail: loggedInUser.emailId,
                securityInfo,
                actionType: 'delete_post',
                isAdmin: loggedInUser.isAdmin || false
            };

            const response = await fetch(`${backendDomain}/wordle/delete-post`, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload),
            });

            const data = await response.json();
            
            if (data.status === 200) {
                window.location.reload();
            } else {
                console.error('Failed to delete post:', data.message);
                // Handle error appropriately
            }
        } catch (error) {
            console.error('Error during post deletion:', error);
            // Handle error appropriately
        }
    };

    // Function to calculate the time difference from the post creation date
    const calculateTimeDifference = (createdAt) => {
        const now = new Date();
        const created = new Date(createdAt);
        const diffInSeconds = Math.floor((now - created) / 1000);

        const intervals = [
            { label: 'y', seconds: 31536000 },
            { label: 'mo', seconds: 2592000 },
            { label: 'd', seconds: 86400 },
            { label: 'h', seconds: 3600 },
            { label: 'm', seconds: 60 },
            { label: 's', seconds: 1 }
        ];

        for (let i = 0; i < intervals.length; i++) {
            const interval = intervals[i];
            const count = Math.floor(diffInSeconds / interval.seconds);
            if (count >= 1) {
                return `${count}${interval.label}`;
            }
        }

        return '0s';
    };

    // Generate the grid and time display for the post
    const grid = wordle.grid;
    const timeDisplay = calculateTimeDifference(wordle.createdAt).replace(' ', '');

    // Function to navigate to the post detail page
    const handleCommentClick = () => {
        navigate(`/post/${wordle._id}`);
    };

    // Function to format the date for the FeedDateHeader
    const formatDateHeader = (date) => {
        const createdDate = startOfDay(parseISO(date));
        const todayUTC = startOfDay(new Date());

        if (isToday(createdDate)) {
            return "Today";
        } else if (isYesterday(createdDate)) {
            return "Yesterday";
        } else {
            return format(createdDate, 'EEE, d MMM');
        }
    };

    // New function to determine battle state
    const getBattleState = () => {
        if (!wordle.battles || !loggedInUser._id) return null;
        try {
            // Check if battles is an array of objects (new format)
            if (wordle.battles.length > 0 && typeof wordle.battles[0] === 'object') {
                const userBattle = wordle.battles.find(battle => 
                    battle.challengerId && battle.challengerId.toString() === loggedInUser._id.toString()
                );
                return userBattle ? userBattle.battleResult : null;
            } 
            // If battles is an array of IDs (old format)
            else {
                // Here we can't determine the battle state directly
                // We'll return a special value to indicate an old format battle exists
                return wordle.battles.length > 0 ? 'old_format' : null;
            }
        } catch (error) {
            console.error('Failed to get battle state:', error);
            return null;
        }
    };

    const battleState = getBattleState();

    // Update handleBattleClick function
    const handleBattleClick = async () => {
        if (isBattleStarting) return;

        setIsBattleStarting(true);
        try {
            const battle = await battleService.startBattle(wordle._id, loggedInUser._id);
            console.log("Battle response:", battle);
            if(battle.status === 403)
            {
                setShowBattleWordlePrompt(true);
                setIsBattleStarting(false);
            }
            else
            {
                navigate(`/battle/${battle.battleId}`);
            }
        } catch (error) {
            console.error('Failed to start battle:', error);
        } finally {
            setIsBattleStarting(false);
        }
    };

    const handleWordlePromptCancel = () => {
        setShowBattleWordlePrompt(false);
        setShowPlayWordlePrompt(false);
    };

    const handlePlayNowClick = () => {
        setShowBattleWordlePrompt(false);
        navigate('/wordle'); // Adjust this path if needed
    };

    return (
        <>
            {isFirstOfDay && !isProfilePage && (
                <div className="feed-date-header">
                    {formatDateHeader(wordle.createdAt)}
                </div>
            )}
            <div className="post-component">
                <div className="user-avatar-container">
                    <img src={user.imageUri} alt="user" className="user-avatar" onClick={handleNameClick} />
                </div>
                <div className="post-content-wrapper">
                    <div className="post-header">
                        <div className="user-info">
                            <span className="user-name" onClick={handleNameClick}>
                                {`${user.firstName} ${user.lastName}`}
                            </span>
                            {wordle.streak > 0 && (
                                <span className="user-visits">{`${wordle.streak}🔥`}</span>
                            )}
                            <span className="time">{timeDisplay}</span>
                        </div>
                    </div>
                    {/* Post content including message and Wordle grid */}
                    <div className="post-content">
                        <div className="post-message">
                            {wordle.message.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    {index < wordle.message.split('\n').length - 1 && <br />}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="wordle-grid">
                            {grid.map((row, rowIndex) => (
                                <div key={rowIndex} className="wordle-row">
                                    {row.map((color, colIndex) => (
                                        <div key={colIndex} className={`wordle-cell ${color}`}></div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Post footer with like button, like count, and delete button */}
                    <div className="post-footer">
                        {liked ? (
                            <FavoriteIcon
                                className="heart-icon liked"
                                onClick={handleLikeClick}
                                style={{ cursor: loggedInUser.emailId ? 'pointer' : 'not-allowed' }}
                            />
                        ) : (
                            <FavoriteBorderIcon
                                className="heart-icon"
                                onClick={handleLikeClick}
                                style={{ cursor: loggedInUser.emailId ? 'pointer' : 'not-allowed' }}
                            />
                        )}
                        <span className="likes-count" onClick={handleLikesNumberClick}>
                            {likeCount}
                        </span>
                        <FaRegComment
                            className="comment-icon"
                            onClick={handleCommentClick}
                            style={{ cursor: 'pointer' }}
                        />
                        <span className="comments-count" onClick={handleCommentClick}>
                            {commentCount}
                        </span>
                        {wordle.userId && wordle.userId !== loggedInUser._id && (
                            <LuSwords
                                className={`battle-icon ${battleState || ''}`}
                                onClick={handleBattleClick}
                                style={{ 
                                    cursor: isBattleStarting ? 'not-allowed' : 'pointer',
                                }}
                            />
                        )}
                        {(loggedInUser.emailId === wordle.email || loggedInUser.isAdmin) && (
                            <DeleteIcon
                                className="delete-icon"
                                onClick={handleDeleteClick}
                                style={{ color: 'lightcoral', cursor: 'pointer' }}
                            />
                        )}
                    </div>
                </div>
                {showLikesOverview && (
                    <LikesOverview likers={wordle.likers} onClose={closeLikesOverview} />
                )}
                {showDeleteDialog && (
                    <div className="delete-dialog">
                        <h2>Remove Post</h2> {/* Updated title */}
                        <p>Are you sure you want to delete this post?</p> {/* Updated content */}
                        <div className="delete-dialog-buttons">
                            <button className="cancel-button" onClick={handleDeleteCancel}>Cancel</button> {/* Updated button */}
                            <button className="delete-button" onClick={handleDeleteConfirm}>Delete</button> {/* Updated button */}
                        </div>
                    </div>
                )}
                {showBattleWordlePrompt && (
                    <div className="delete-dialog">
                        <h3>Not Battle Ready</h3>
                        <p>Complete today's Wordle to start a battle</p>
                        <div className="delete-dialog-buttons">
                            <button className="cancel-button" onClick={handleWordlePromptCancel}>Cancel</button>
                            <button className="play-now" onClick={handlePlayNowClick}>Play Today's Wordle</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );

};

export default PostOverview;