import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { backendDomain } from "../constants";
import './css/LikesOverview.css';

const LikesOverview = ({ likers, onClose }) => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    const handleUserClick = (id) => {
        navigate(`/profile/${id}`);
        onClose();
    };

    const handleOverlayClick = (e) => {
        // Close the overlay if the user clicks outside the likes-container
        if (e.target.classList.contains('likes-overlay')) {
            onClose();
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            document.body.style.overflow = 'hidden';
            try {
                const response = await fetch(`${backendDomain}/user/bulk-query-user-details`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ emails: likers }),
                });

                const data = await response.json();

                if (data.status === 200) {
                    console.log(data.users)
                    setUsers(data.users);
                } else {
                    console.error('Failed to fetch users:', data);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [likers]);

    return (
        <div className="likes-overlay" onClick={handleOverlayClick}>
            <div className="likes-container">
                <div className="likes-header">
                    <button className="back-button" onClick={onClose}>Back</button>
                    <h2>Likes</h2>
                </div>
                <div className="likes-list">
                    {users.map((user) => (
                        <div
                            key={user.id}
                            className="likes-item"
                            onClick={() => handleUserClick(user._id)}
                        >
                            <img src={user.imageUri} alt={`${user.firstName} ${user.lastName}`} className="user-avatar-likes" />
                            <span>{`${user.firstName} ${user.lastName}`}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LikesOverview;
