import React from 'react'
import './css/Grid.css'
// components
import Row from './Row'

// GridBattle component for a different game mode
export default function GridBattle({ guesses, currentGuess, turn, solution, formattedSolution, battleDayWordleSolution }) {
  console.log("Guesses in GridBattle:", guesses);
  
  return (
    <div className='grid-wordle'>
      {/* First row: battleDayWordleSolution */}
      <Row specialRow="daily" guess={battleDayWordleSolution.split('').map(letter => ({ key: letter, color: 'daily' }))} />
      
      {/* Second row: solution compared to battleDayWordleSolution (hidden letters) */}
      <Row specialRow="solution" guess={formattedSolution} />
    
      {/* Remaining 4 rows for user guesses */}
      {guesses.map((g, i) => {
        if (turn === i) {
          return <Row key={i} currentGuess={currentGuess} />
        }
        return <Row key={i} guess={g} />
      })}
    </div>
  )
}
