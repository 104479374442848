import React from 'react'
import Navbar from './Navbar'
import StaticNavHeader from './StaticNavHeader'

const Header = () => {
    return (
        <>
            <StaticNavHeader />
            <Navbar />
        </>
    )
}

export default Header