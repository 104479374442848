import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import './css/WordleGreeting.css';

const AnimatedGreeting = ({ name, isLoggedIn, onPlayClick, onLoginSuccess, animationShown }) => {
    const [displayedWords, setDisplayedWords] = useState([]);
    const [showSubtext, setShowSubtext] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [showSecondSentence, setShowSecondSentence] = useState(false);
    const [showSocialWordle, setShowSocialWordle] = useState(false);

    const getGreeting = () => {
        if (isLoggedIn) {
            const hour = new Date().getHours();
            if (hour < 12) return `Good Morning`;
            if (hour < 17) return `Good Afternoon`;
            return `Good Evening`;
        }
        return "Welcome to";
    };

    const greeting = getGreeting();

    useEffect(() => {
        if (animationShown) {
            setDisplayedWords(greeting.split(' '));
            setShowSocialWordle(true);
            setShowSubtext(true);
            setShowButton(true);
            if (!isLoggedIn) {
                setShowSecondSentence(true);
            }
            return;
        }

        let currentIndex = 0;
        const words = greeting.split(' ');

        const intervalId = setInterval(() => {
            if (currentIndex < words.length) {
                setDisplayedWords(prev => [...prev, words[currentIndex]]);
                currentIndex++;
            } else {
                clearInterval(intervalId);
                setShowSocialWordle(true);
                setTimeout(() => {
                    setShowSubtext(true);
                    setTimeout(() => {
                        setShowButton(true);
                        if (!isLoggedIn) {
                            setShowSecondSentence(true);
                        }
                    }, 500);
                }, 500);
            }
        }, 400);

        return () => clearInterval(intervalId);
    }, [greeting, animationShown, isLoggedIn]);

    return (
        <div className="animated-greeting">
            <div className="greeting-container">
                <h1 className="greeting-text">
                    {displayedWords.map((word, index) => (
                        <span key={index} className="word">
                            {word} 
                        </span>
                    ))}
                </h1>
                {!isLoggedIn && showSocialWordle && (
                    <h2 className="brand-text fade-in">
                        socialWordle
                    </h2>
                )}
                {isLoggedIn && name && (
                    <h2 className={`name-text ${displayedWords.length === greeting.split(' ').length ? 'fade-in' : ''}`}>
                        {name}
                    </h2>
                )}
            </div>
            <div className="content-container">
                {showSubtext && (
                    <p className="subtext fade-in">
                        {isLoggedIn 
                            ? "Today's Wordle is waiting for you 🎁"
                            : (
                                <>
                                    😊 Play Wordle with Friends ❤️
                                    <span className="login-prompt">
                                        Login to start playing
                                    </span>
                                </>
                            )}
                    </p>
                )}
                {showButton && (
                    isLoggedIn ? (
                        <button className="play-button fade-in" onClick={onPlayClick}>
                            Play Now
                        </button>
                    ) : (
                        <div className="login-button-container fade-in">
                            <GoogleLogin
                                onSuccess={onLoginSuccess}
                                onError={() => console.log('Login Failed')}
                                useOneTap
                                text="Login and Play"
                            />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default AnimatedGreeting;
