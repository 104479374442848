/**
 * PostDetail component displays the details of a specific post,
 * including its content and associated comments. It allows users
 * to add new comments to the post.
 */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { backendDomain } from "../constants";
import Comment from './Comment';
import PostOverview from './PostOverview';
import './css/PostDetail.css';
import './css/PostOverview.css';

const PostDetail = ({ user, wordle }) => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [originalposter, setUser] = useState(null);  // Add this line
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const loggedInUser = useSelector((state) => state.user);
    const navigate = useNavigate();

    /**
     * Fetches the post and its comments from the backend when the component mounts.
     */
    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await fetch(`${backendDomain}/wordle/${postId}`);
                const data = await response.json();
                if (data.status === 200) {
                    setPost(data.post);
                    setComments(data.comments);
                    
                    // Fetch user data
                    const userResponse = await fetch(`${backendDomain}/user/fetch-user`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ id: data.post.userId }),
                    });
                    const userData = await userResponse.json();
                    if (userData.status === 200) {
                        setUser(userData.user);
                    } else {
                        console.error('Failed to fetch user data:', userData.message || 'Unknown error');
                    }
                } else {
                    console.error('Failed to fetch post:', data.message || 'Unknown error');
                }
            } catch (error) {
                console.error('Error fetching post:', error.message);
            }
        };
        fetchPost();
    }, [postId]);

    /**
     * Handles the submission of a new comment.
     * Sends the comment to the backend and updates the comments state.
     * 
     * @param {Event} e - The event object.
     */
    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log("Going to save comment for post: ", postId, " with user: ", loggedInUser.emailId, " and comment: ", newComment);
            const response = await fetch(`${backendDomain}/wordle/${postId}/comments`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ wordle_id: postId, content: newComment, user_email: loggedInUser.emailId, userId: loggedInUser._id }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            // Update the comments state with the new comment
            console.log("New comment added:", data.content);
            setComments(prevComments => [...prevComments, data]);
            setNewComment("");

            // Scroll to the new comment
            setTimeout(() => {
                const newCommentElement = document.getElementById(data.content._id);
                if (newCommentElement) {
                    newCommentElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
            
        } catch (error) {
            console.error('Error submitting comment:', error.message);
            // You might want to set an error state here to display to the user
        }
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    useEffect(() => {
        document.body.classList.add('viewing-comments');
        return () => {
            document.body.classList.remove('viewing-comments');
        };
    }, []);

    useEffect(() => {
        if (comments.length > 0 && window.location.hash) {
            // Try multiple times with increasing delays
            const delays = [100, 500, 1000];
            
            delays.forEach(delay => {
                setTimeout(() => {
                    const commentId = window.location.hash.replace('#comment-', '');
                    const commentElement = document.getElementById(`comment-${commentId}`);
                    if (commentElement) {
                        commentElement.scrollIntoView({ 
                            behavior: 'smooth',
                            block: 'center'  // Center the comment in the viewport
                        });
                    }
                }, delay);
            });
        }
    }, [comments]);

    if (!post || !originalposter) return <div>Loading...</div>;

    return (
        <div className="post-detail">
            <PostOverview user={originalposter} wordle={post} />
            <div className="comments-section">
                {comments.map(comment => (
                    <Comment key={comment._id} id={comment._id} comment={comment} />
                ))}
                {loggedInUser.emailId ? (
                    <form onSubmit={handleCommentSubmit} className="comment-form">
                        <input
                            type="text"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Add a comment..."
                            className="comment-input"
                        />
                        <button type="submit" className="comment-submit">Post Comment</button>
                    </form>
                ) : (
                    <div className="login-cta">
                        <p>Login to Post Comment</p>
                        <button onClick={() => {/* Logic to open login popup */}} className="login-cta-button">
                            Login to Post Comment
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PostDetail;
